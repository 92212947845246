<template>
  <hr class="separator " style="margin-top: 1px"/>

  <div>
    <div class="custom-text">
      {{ $t('ApplyAfterSales.applyAfterSales') }}
    </div>
    <hr class="separator"/>
  </div>

  <div class="parent">
    <SearchCondition @search="handleSearch"/>
    <ApplyAfterSales/>

    <TableDisplay :tasks="tasks" @view-details="handleViewDetails" @delete-task="handleDeleteTask"
                  :loadData="loadData" :loading="loading"/>
  </div>

  <PaginationBar :total-records="totalRecords" :current-page="currentPage" :page-size="pageSize"
                 @page-change="handlePageChange" @page-size-change="handlePageSizeChange"/>

</template>

<script>
import SearchCondition from './components/SearchCondition.vue';
import ApplyAfterSales from './components/ApplyAfterSales.vue';
import TableDisplay from './components/TableDisplay.vue';
import PaginationBar from '@/components/PaginationBar.vue';
import {deleteAfterSalesData, getAfterSales} from "@/api/api";

export default {
  emits: ["reset"],
  data() {
    return {
      tasks: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示条数
      totalRecords: 0, // 总记录数
      isSearching: false,
      searchParams: null,
      loading: false, // 加载状态
    };
  },
  components: {
    SearchCondition,
    TableDisplay,
    ApplyAfterSales,
    PaginationBar,
  },
  created() {
    // 在组件创建时加载所有数据
    this.loadData();
  },
  watch : {
    '$i18n.locale'() {
      this.handleSearch(this.searchParams);
    },
  },
  methods: {
    // 处理搜索事件
    async handleSearch(searchParams) {
      const page = this.currentPage;
      const pageSize = this.pageSize;
      this.isSearching = true;
      this.searchParams = searchParams;
      this.loading = true; // 开始加载
      try {
        const response = await getAfterSales(page, pageSize, searchParams);
        // 在这里进行数据转换
        this.tasks = response.data.map(item => ({
          after_sales_id: item.id,
          taskNumber: item.serial_number,
          customerName: item.customer_name, // 根据值映射中文名称
          country: this.$t(`countries.${item.country_code}`),

          address: item.address,
          brand: item.brand,
          phone: item.phone,
          deliveryAddress: item.pick_up_address,
          email: item.email,
          contact: item.contact,
          commitStatus: item.commit_status,
          submitProgress: item.commit_status === 0 ? this.$t('ApplyAfterSales.notSubmitted') : this.$t('ApplyAfterSales.submitted'),
          processProgress: item.process_status !== undefined ? this.mapProcessingProgress(item.process_status) : this.$t('ApplyAfterSales.unknown'),

        }));

        this.totalRecords = response.meta.total;
        this.currentPage = response.meta.current_page;
        this.pageSize = parseInt(response.meta.per_page);
      } catch (error) {
        // 添加错误提示
        console.error(this.$t('ProductManage.searchFailed'));
      } finally {
        this.loading = false; // 加载结束
      }
    },

    // 处理查看详情事件
    handleViewDetails(task) {
      // 打开查看详情页面或弹框
      this.$router.push(`/ClientDetail/${task.after_sales_id}`);
    },

    // 处理分页变化事件
    async handlePageChange(newPage) {
      // 异步操作的处理逻辑
      try {
        this.currentPage = newPage;
        // 根据新的页码发送请求获取数据，更新tableData
        if (this.isSearching) {
          await this.handleSearch(this.searchParams);
        } else {
          await this.loadData();
        }
      } catch (error) {
        console.error(this.$t('ProductManage.loadDataFailed'));
      }
    },

    // 处理每页显示条数变化事件
    async handlePageSizeChange(newPageSize) {
      // 异步操作的处理逻辑
      try {
        this.pageSize = typeof newPageSize === 'string' ? parseInt(newPageSize, 10) : newPageSize;
        // 根据新的页码发送请求获取数据，更新tableData
        if (this.isSearching) {
          await this.handleSearch(this.searchParams);
        } else {
          await this.loadData();
        }
      } catch (error) {
        console.error(this.$t('ProductManage.loadDataFailed'));
      }
    },

    async loadData() {
      this.isSearching = false;
      const page = this.currentPage;
      const pageSize = this.pageSize;
      this.loading = true; // 开始加载
      try {
        const data = await getAfterSales(page, pageSize);

        // 将从服务器获取的数据进行字段重命名
        this.tasks = data.data.map(item => ({

          after_sales_id: item.id,
          taskNumber: item.serial_number,
          customerName: item.customer_name, // 根据值映射中文名称
          country: this.$t(`countries.${item.country_code}`),
          address: item.address,
          brand: item.brand,
          phone: item.phone,
          deliveryAddress: item.pick_up_address,
          email: item.email,
          contact: item.contact,
          commitStatus: item.commit_status,
          submitProgress: item.commit_status === 0 ? this.$t('ApplyAfterSales.notSubmitted') : this.$t('ApplyAfterSales.submitted'),
          processProgress: item.process_status !== undefined ? this.mapProcessingProgress(item.process_status) : this.$t('ApplyAfterSales.unknown'),
        }));

        this.totalRecords = data.meta.total;
        this.currentPage = data.meta.current_page;
        this.pageSize = typeof data.meta.per_page === 'string' ? parseInt(data.meta.per_page, 10) : data.meta.per_page;
      } catch (error) {
        console.error(this.$t('ProductManage.loadDataFailed'));
      } finally {
        this.loading = false; // 加载结束
      }
    },
    mapProcessingProgress(progress) {
      switch (progress) {
        case 0:
          return this.$t('ApplyAfterSales.unprocessed');
        case 1:
          return this.$t('ApplyAfterSales.processing');
        case 2:
          return this.$t('ApplyAfterSales.completed');
        default:
          return this.$t('ApplyAfterSales.unknown');
      }
    },

    //删除接口
    async handleDeleteTask(row) {
      try {
        await deleteAfterSalesData(row.after_sales_id);
        await this.loadData();
      } catch (error) {
        // 添加错误提示
        console.error(this.$t('ProductManage.deleteDataFailed'));
      }
    },

  },
};
</script>

<style scoped>
@import "@/assets/css/publicStyle.css";

.custom-text {
  margin-left: 15px;
  /* 可以根据需要调整这个值 */
}
</style>