<template>
  <div>
    <div class="custom-text">
      {{ $t('ApplyAfterSales.applyAfterSales') }}
    </div>
  </div>

  <div>
    <el-steps :active="1" align-center>
      <el-step :title="$t('ApplyAfterSales.basicInformation')"/>
      <el-step :title="$t('ApplyAfterSales.malfunctionInformation')"/>
    </el-steps>
  </div>
  <div class="form-page">
    <div class="header">
    </div>
    <div class="form">
      <div class="form-section">
        <el-form :model="formData" label-width="150px" ref="form">
          <!-- 第一行 -->
          <el-row class="custom-row">

            <el-col :span="12" class="custom-col">
              <el-form-item :label="$t('ApplyAfterSales.customerName')" prop="customerName" label-width="150px"
                            :rules="{ required: true, message: $t('ApplyAfterSales.customerNamePlaceholder'), trigger: 'blur' }">
                <el-input class="custom-input-width" v-model="formData.customerName" required
                          :placeholder="$t('ApplyAfterSales.customerNamePlaceholder')"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="1"></el-col> <!-- 减小间隔列的宽度 -->

            <el-col :span="11">
              <el-form-item prop="country" :label="$t('ApplyAfterSales.country')" label-width="150px"
                            :rules="{ required: true, message: $t('ApplyAfterSales.countryEnterPlaceholder'), trigger: 'blur' }">
                <el-select class="custom-input-width" v-model="formData.country" filterable clearable
                           :placeholder="$t('ApplyAfterSales.countryPlaceholder')">
                  <el-option v-for="item in countryOptions" :key="item.value" :label="item.label" :value="item.value"/>
                </el-select>
              </el-form-item>
            </el-col>

          </el-row>

          <!-- 第二行 -->
          <!-- 重复以上结构对第二行进行修改 -->
          <el-row>
            <el-col :span="12" class="custom-col">
              <el-form-item :label="$t('ApplyAfterSales.address')" prop="address" label-width="150px"
                            :rules="{ required: true, message: $t('ApplyAfterSales.addressPlaceholder'), trigger: 'blur' }">
                <el-input class="custom-input-width" v-model="formData.address" required
                          :placeholder="$t('ApplyAfterSales.addressPlaceholder')"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1"></el-col>
            <el-col :span="11">
              <el-form-item :label="$t('ApplyAfterSales.brand')" prop="brand" label-width="150px"
                            :rules="{ required: true, message: $t('ApplyAfterSales.brandPlaceholder'), trigger: 'blur' }">
                <el-input class="custom-input-width" v-model="formData.brand" required
                          :placeholder="$t('ApplyAfterSales.brandPlaceholder')"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 第三行 -->
          <!-- 重复以上结构对第三行进行修改 -->
          <el-row>
            <el-col :span="12" class="custom-col">
              <el-form-item :label="$t('ApplyAfterSales.phone')" prop="phone" label-width="150px"
                            :rules="{ required: true, message: $t('ApplyAfterSales.phonePlaceholder'), trigger: 'blur' }">
                <el-input class="custom-input-width" v-model="formData.phone" required
                          :placeholder="$t('ApplyAfterSales.phonePlaceholder')"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1"></el-col>
            <el-col :span="11">
              <el-form-item :label="$t('ApplyAfterSales.pICKUPADDRESS')" prop="pickupAddress" label-width="150px"
                            :rules="{ required: true, message: $t('ApplyAfterSales.pickupAddressPlaceholder'), trigger: 'blur' }">
                <el-input class="custom-input-width" v-model="formData.pickupAddress" required
                          :placeholder="$t('ApplyAfterSales.pickupAddressPlaceholder')"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 第四行 -->
          <!-- 重复以上结构对第四行进行修改 -->
          <el-row>
            <el-col :span="12" class="custom-col">
              <el-form-item label=" E-MAIL" prop="email" label-width="150px" :rules="[
        { required: true, message: $t('ApplyAfterSales.emailPlaceholder'), trigger: 'blur' },
        {
          pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
          message: $t('ApplyAfterSales.enterEmailFormat'),
          trigger: 'blur',
        },
      ]">
                <el-input class="custom-input-width" v-model="formData.email" required
                          :placeholder="$t('ApplyAfterSales.emailPlaceholder')"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1"></el-col>
            <el-col :span="11">
              <el-form-item :label="$t('ApplyAfterSales.cONTACTPERSON')" prop="contact" label-width="150px"
                            :rules="{ required: true, message: $t('ApplyAfterSales.contactPersonPlaceholder'), trigger: 'blur' }">
                <el-input class="custom-input-width" v-model="formData.contact" required
                          :placeholder="$t('ApplyAfterSales.contactPersonPlaceholder')"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div class="form-actions">
        <el-button @click="goBack" class="custom-reset-button"> {{ $t('ApplyAfterSales.return') }}</el-button>
        <el-button class="button-background-color" @click="goToNextStep">{{
            $t('ApplyAfterSales.nextStep')
          }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {addAfterSalesData} from "@/api/api";
// 引入 i18n-iso-countries
import i18nIsoCountries from 'i18n-iso-countries';
import dialogMixin from "@/mixins/dialogMixin";
import countryMixin from "@/mixins/countryMixin";
// 初始化 i18n-iso-countries，选择你需要的语言
i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));

export default {
  props: ['out-formData'],
  mixins: [dialogMixin,countryMixin],
  data() {
    return {
      activeStep: 1, // 当前步骤
      formData: {
        customerName: '',
        address: '',
        phone: '',
        brand: '',
        country: null,
        contact: '',
        pickupAddress: '',
        email: '',
      },
      countryOptions: [],
    };
  },
  created() {
    try {
      if (this.$route.query.formData) {
        this.formData = JSON.parse(this.$route.query.formData); // 解析JSON字符串
      }
    } catch (error) {
      console.error("Error parsing formData:", error);
      this.formData = {}; // 确保初始化数据结构
    }
  },

  mounted() {
    // 调用后端接口获取国家列表
    this.fetchCountryOptions();
  },
  methods: {
    goBack() {
      // 使用 Vue Router 返回上一个页面
      this.$router.go(-1); // 或者使用 this.$router.back();
    },
    async goToNextStep() {
      await this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            // 表单校验成功，尝试提交数据
            let res = await this.addDataToBackend();
            if (res?.code !== 0) {
              console.error(this.$t('ApplyAfterSales.addDataFailed') + (res?.message || ''));
              return;
            }

            // 数据添加成功，处理成功逻辑，例如跳转或通知
            if (this.activeStep < 2) {
              this.activeStep++;
              this.$router.push({path: `/FaultPage/${res.data.id}`, query: {type: 'new',formData: JSON.stringify(this.formData), }});
            }
          } catch (error) {
            // 处理保存失败的情况
            console.error(this.$t('ApplyAfterSales.retryLater'));
          }
        } else {
          // 表单校验失败
          console.error(this.$t('ApplyAfterSales.checkEntries'));
        }
      });
    },

    // 新增保存数据的方法
    async addDataToBackend() {
      try {
        const organizationId = localStorage.getItem('organizationId');
        let searchCondition = {
          serial_number: 0,
          organization_id: organizationId,
          customer_name: this.formData.customerName,
          country_id: this.formData.country,
          address: this.formData.address,
          brand: this.formData.brand,
          phone: this.formData.phone,
          pick_up_address: this.formData.pickupAddress,
          email: this.formData.email,
          contact: this.formData.contact,
          commit_status: 0,
          commit_at: null,
          process_status: 0,
        };
        return await addAfterSalesData(searchCondition)
      } catch (error) {
        // 添加错误提示
        console.error(this.$t('ApplyAfterSales.retryLater'));
      }
    },

  },
};
</script>

<style scoped>

.custom-row {
  display: flex;
  align-items: center; /* 垂直居中对齐 */
}

.custom-col {
  padding-right: 10px;
  padding-left: 10px;
}

/* 页面样式 */
.form-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

/* 标题栏样式 */
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* 表单样式 */
.form {
  width: 80%;
}

/* 表单部分样式 */
.form-section {
  margin-top: 20px;
  padding: 20px;
}

/* 操作按钮样式 */
.form-actions {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

/* 默认样式，适用于大屏幕 */
.custom-input-width {
  width: 434.52px;
  /* 大屏幕上宽度为 430px */
}

/* 对于中等屏幕设备 */
@media (max-width: 1024px) {
  .custom-input-width {
    width: 350px;
    /* 中等屏幕上宽度为 350px */
  }
}

/* 对于小屏幕设备 */
@media (max-width: 600px) {
  .custom-input-width {
    width: 250px;
    /* 小屏幕上宽度为 250px */
  }
}
</style>