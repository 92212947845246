<template>
  <div>
    <div class="custom-text">
      {{ $t('ApplyAfterSales.applyAfterSales') }}
    </div>
  </div>

  <div>
    <!-- 详情页面头部信息 -->
    <TextInfo :infoData="customerArray" :loading="loading"/>

    <div class="add-motor" style="margin-bottom: 50px;">
      <div class="save-time">{{ $t('ApplyAfterSales.lastSavedTime') }}: {{ customer.lastSavedTime }}</div>
    </div>

    <!-- 表格展示区域 -->
    <TableShow :tableData="tableData" :loading="loading" :showOperationColumn="false"/>

    <!-- 分页 -->
    <div class="pagination">

      <PaginationBar :total-records="totalRecords" :current-page="currentPage" :page-size="pageSize"
                     @page-change="handlePageChange" @page-size-change="handlePageSizeChange"/>

    </div>
    <!-- 返回按钮 -->
    <el-button @click="goBack" class="custom-reset-button back-button">{{ $t('ApplyAfterSales.return') }}</el-button>
  </div>

</template>

<script>
import PaginationBar from '@/components/PaginationBar.vue';

import {getCountryOptions} from "@/mixins/Mixin";
import {getAfterSalesDetails, showAfterSalesData} from "@/api/api";
import dialogMixin from "@/mixins/dialogMixin";
import TableShow from "@/views/AfterSales/editTask/components/TableShow.vue";
import TextInfo from "@/views/ServiceCenter/components/DetailInfoComponents/TextInfo.vue";
import countryMixin from "@/mixins/countryMixin";

export default {
  components: {
    TextInfo,
    TableShow,
    PaginationBar
  },
  data() {
    return {
      customer: {
        customer_name: '',
        country: '',
        address: '',
        brand: '',
        phone: '',
        delivery_address: '',
        email: '',
        contact_person: '',
        lastSavedTime: '',
      },
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      totalRecords: 0,
      imageUrl: '',
      countryOptions: [],
      invoiceUrl: '',
      defectImageUrl: '',
      currentVideoUrl: '',

      loading: false, // 加载状态
    };
  },
  props: {
    taskId: String,
  },
  mixins: [dialogMixin,countryMixin],
  created() {
    this.loadCustomerData();
  },
  mounted() {
    this.fetchCountryOptions();
  },
  watch : {
    '$i18n.locale'() {
      this.loadCustomerData();
    },
  },
  computed: {
    customerArray() {
      return Object.entries(this.customer).map(([key, value]) => ({
        [key]: value,
      }));
    },
  },
  methods: {
    goBack() {
      // 返回逻辑，可以使用路由或其他方式
      this.$router.go(-1);
    },

    async handlePageChange(newPage) {
      // 异步操作的处理逻辑
      try {
        this.currentPage = newPage;
        // 根据新的页码发送请求获取数据，更新tableData
        await this.loadCustomerData();
      } catch (error) {
        // 添加错误提示
        console.error(this.$t('ProductManage.loadDataFailed'));
      }
    },

    // 处理每页显示条数变化事件
    async handlePageSizeChange(newPageSize) {
      // 异步操作的处理逻辑
      try {
        this.pageSize = typeof newPageSize === 'string' ? parseInt(newPageSize, 10) : newPageSize;
        // 根据新的页码发送请求获取数据，更新tableData
        await this.loadCustomerData();
      } catch (error) {
        // 添加错误提示
        console.error(this.$t('ProductManage.loadDataFailed'));
      }
    },

    async loadCustomerData() {
      const id = this.$route.params.taskId;
      this.loading = true; // 开始加载
      try {
        const query = {'after_sale_id-eq': id};
        // 调用后端接口获取数据
        const afterSalesInformation = await showAfterSalesData(id);
        // 将后端返回的数据填充到表单中
        this.customer.customer_name = afterSalesInformation.data.customer_name;
        this.customer.address = afterSalesInformation.data.address;
        this.customer.phone = afterSalesInformation.data.phone;
        this.customer.email = afterSalesInformation.data.email;
        this.customer.country = this.$t(`countries.${afterSalesInformation.data.country_code}`);

        this.customer.brand = afterSalesInformation.data.brand;
        this.customer.delivery_address = afterSalesInformation.data.pick_up_address;
        this.customer.contact_person = afterSalesInformation.data.contact;

        const page = this.currentPage;
        const pageSize = this.pageSize;
        const responseTableData = await getAfterSalesDetails(page, pageSize, query);

        this.tableData = responseTableData.data.map(item => ({
          after_sales_id: item.after_sale_id,
          motorAppearance: item.appearance_status,
          defectDescription: item.description,
          defectCategory: item.error_type,
          id: item.id,
          defectImages: item.image,
          image_url:  item.image_url,
          invoice: item.invoice,
          invoice_url: item.invoice_url,
          materialCode: item.material_code,
          SN: item.sn, // 根据值映射中文名称
          SNLabel: item.sn_label,
          sn_label_url : item.sn_label_url,
          defectVideos: item.video,
          video_url: item.video_url,
        }));

        this.customer.lastSavedTime = afterSalesInformation.data.commit_at;

        this.totalRecords = responseTableData.meta.total;
        this.currentPage = responseTableData.meta.current_page;
        this.pageSize = parseInt(responseTableData.meta.per_page);
      } catch (error) {
        // 添加错误提示
        this.$message.error(this.$t('ApplyAfterSales.dataFetchError'));
      } finally {
        this.loading = false; // 加载结束
      }
    },
    // 更新国家选项
    updateCountryOptions() {
      const currentLang = this.$i18n.locale;

      // 在这里执行获取国家选项的逻辑
      this.countryOptions = getCountryOptions(currentLang);
    },
  },
};
</script>
<style scoped>
/* 默认样式，适用于大屏幕 */
.back-button {
  margin-top: 10px;
  margin-bottom: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* 中等屏幕 (大于 768px 并且小于等于 1024px 宽) */
@media (min-width: 769px) and (max-width: 1024px) {
  .back-button {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

/* 小屏幕 (小于 768px 宽) */
@media (max-width: 768px) {
  .back-button {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.el-form-item {
  width: 100%; /* 确保 form-item 填满 col */
}

:deep(.el-select .el-input__inner) {
  width: 100%; /* 确保 select 的输入部分填满 form-item */
}

/* 如果el-select的箭头导致宽度问题，可以尝试以下样式 */
:deep( .el-select) {
  width: 100% !important; /* 强制 select 的整体宽度为100% */
}

:deep(.el-input.is-disabled .el-input__inner ){
  background-color: rgb(245, 245, 245); /* 您想要的背景色 */
}

.left-spaced-div {
  /*margin-left: -20px; !* 如果你想整个元素向右移动 *!x*/
  margin-right: 60px;
  margin-top: 30px;
  margin-bottom: -10px;
}

.data-table {
  /*margin: 10px 20px; !* 设置默认的左右间隔 *!*/
  margin: 10px 16px 13px 16px;

  @media (max-width: 768px) {
    /*margin: 5px 10px; !* 在小屏幕下减小间隔 *!*/
    margin: 10px 16px -5px 16px;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    /*margin: 15px 30px; !* 在中等屏幕下增加间隔 *!*/
    margin: 10px 16px -5px 16px;
  }

  /* 添加其他屏幕尺寸的媒体查询和 margin 设置 */
}

.save-time {
  font-size: 14px; /* 调整字体大小 */
}

.pagination {
  margin-top: 0; /* 增加分页栏的顶部间距 */
  /* 如果需要，也可以添加左右间距 */
}

/* 中等屏幕 (大于 768px 并且小于等于 1024px 宽) */
@media (min-width: 769px) and (max-width: 1024px) {
  .pagination {
    margin-top: 10px; /* 中等屏幕上适当的间距 */
  }
}

/* 小屏幕 (小于 768px 宽) */
@media (max-width: 768px) {
  .pagination {
    margin-top: 20px; /* 小屏幕上更大的间距 */
  }
}

</style>
