<template>

  <div class="pagination">
    <el-pagination :current-page="currentPage" :page-size="pageSize" :page-sizes="[10, 20, 50, 100]" :small="small"
      :disabled="disabled" :background="background" layout="total,prev, pager, next, sizes,jumper, ->" :total="totalRecords"
      @size-change="handleSizeChange" @current-change="handleCurrentPageChange" />
      </div>
      
  </template>
  
  <script>
  export default {
    props: {
      totalRecords: {
        type: Number,
        required: true,
      },
      currentPage: {
        type: Number,
        required: true,
      },
      pageSize: {
        type: Number,
        required: true,
      },
      small: Boolean, // 定义 small 属性为布尔类型
      disabled: Boolean, // 定义 disabled 属性为布尔类型
      background: Boolean, // 定义 background 属性为布尔类型
    },
    methods: {
      handleSizeChange(newPageSize) {
        this.$emit('page-size-change', newPageSize);
      },
      handleCurrentPageChange(newPage) {
        this.$emit('page-change', newPage);
      },
    },
  };
  </script>
    