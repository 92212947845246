<template>
  <div class="sales-button">
    <el-button class="button-background-color apply-after-sales-button" @click="navigateToFormPage">
      {{ $t('ApplyAfterSales.applyforAfterSales') }}
    </el-button>
  </div>
</template>

<script>
import {useRouter} from 'vue-router'; // 使用 useRouter

export default {
  setup() {
    const router = useRouter(); // 使用 useRouter

    const navigateToFormPage = () => {
      router.push('/FormPage'); // 使用路由导航到新页面
    };

    return {
      navigateToFormPage,
    };
  },
};
</script>

<style scoped>
.apply-after-sales-button {
  padding: 10px 20px; /* 设置内边距，使按钮更大 */
  margin-left: 10px; /* 设置按钮距离内容左侧的距离 */
  margin-top: 20px; /* 增加上边距，可以根据需要调整距离大小 */
  margin-bottom: 20px;
}

.sales-button {
  display: flex;
  justify-content: flex-start;
  margin-left: 15px; /* 设置按钮距离内容左侧的距离 */

}
</style>
