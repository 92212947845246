<template>
  <div class="data-table" v-loading="loading" element-loading-text="Loading...">
    <el-table :header-cell-style="{background:'rgb(250, 250, 250)'}" :data="tasks" :row-style="{ height: '65px' }"
              class="table-height">

      <!-- 表头 -->
      <el-table-column :label="$t('ApplyAfterSales.taskID')" prop="taskNumber"
      ></el-table-column>
      <el-table-column :label="$t('ApplyAfterSales.customerName')" prop="customerName"
      ></el-table-column>
      <!-- 新的表头列 -->
      <el-table-column :label="$t('ApplyAfterSales.country')" prop="country"></el-table-column>
      <el-table-column :label="$t('ApplyAfterSales.address')" prop="address"></el-table-column>
      <el-table-column :label="$t('ApplyAfterSales.brand')" prop="brand"></el-table-column>
      <el-table-column :label="$t('ApplyAfterSales.phone')" prop="phone"></el-table-column>
      <el-table-column :label="$t('ApplyAfterSales.pICKUPADDRESS')" prop="deliveryAddress"
      ></el-table-column>
      <el-table-column :label="$t('ApplyAfterSales.email')" prop="email"></el-table-column>
      <el-table-column :label="$t('ApplyAfterSales.cONTACTPERSON')" prop="contact"
      ></el-table-column>

      <el-table-column :label="$t('ApplyAfterSales.submissionProgress')" prop="submitProgress"
      ></el-table-column>
      <el-table-column :label="$t('ApplyAfterSales.processingProgress')" prop="processProgress"
      ></el-table-column>

      <!-- 其他列 -->
      <el-table-column :label="$t('ApplyAfterSales.operation')"
                       v-if="hasPermission('after_sale_apply.edit') || hasPermission('after_sale_apply.delete')">

        <template v-slot="{ row }">
          <!-- 如果提交进度是已提交（例如假设 submitProgress 为 '1' 表示已提交），则只显示详情 -->
          <el-link v-if="row.commitStatus === 1 " class="el-link--text el-link--success"
                   @click="viewDetails(row)" :underline="false">
            <span class="link-text-style">{{ $t('ApplyAfterSales.details') }}</span>
          </el-link>

          <!-- 如果提交进度是未提交（例如假设 submitProgress 为 '0' 表示未提交），则显示编辑和删除 -->
          <template v-else>
            <el-link class="el-link--text" @click="edit(row)" :underline="false"
                     v-if="hasPermission('after_sale_apply.edit')">
              <span class="link-text-style">{{ $t('ApplyAfterSales.edit') }}</span>
            </el-link>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <el-popconfirm ref="popconfirmRef"
                           :title="$t('ApplyAfterSales.WhetherToDelete')"
                           placement="top-start"
                           @confirm="showDeleteConfirmationDialog(row)"
                           :confirm-button-text="$t('ApplyAfterSales.ok')"
                           :cancel-button-text="$t('ApplyAfterSales.cancel')"
                           confirm-button-type="success"
                           class="custom-popconfirm"
                           width="250"
                           v-if="hasPermission('after_sale_apply.delete')"
            >
              <template #reference>
                <el-button link><span class="link-text-delete">{{ $t('ApplyAfterSales.delete') }}</span></el-button>
              </template>
            </el-popconfirm>
          </template>
        </template>

      </el-table-column>

      <template v-slot:empty>
        <EmptySlot/>
      </template>

    </el-table>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import {ElTable, ElTableColumn, ElLink} from 'element-plus';
import {useRouter} from 'vue-router'; // 使用 useRouter
import resizeMixin from '@/mixins/resizeMixin';
import dialogMixin from "@/mixins/dialogMixin";
import EmptySlot from "@/components/EmptySlot.vue";


export default defineComponent({

  setup() {
    const router = useRouter(); // 使用 useRouter 获取路由实例
    // 编辑按钮的点击事件处理函数
    const edit = (row) => {
      if (row.submitProgress) {
        // 构建编辑页面的路由路径，假设使用任务编号作为参数
        const editPagePath = `/EditTask/${row.after_sales_id}`;
        // 使用路由导航到编辑页面
        router.push(editPagePath);
      }
    };

    return {
      edit, // 返回编辑函数
    };
  },
  mixins: [resizeMixin, dialogMixin],
  data() {
    return {
      deleteDialogVisible: false,
    };
  },
  components: {
    ElTable,
    ElTableColumn,
    ElLink,
    EmptySlot
  },

  props: {
    tasks: Array, // 任务列表数据
    loadData: {
      type: Function,
      required: true,
    },
    loading: Boolean, // 加载状态
  },
  mounted() {
  },
  async created() {
    await this.loadSubPermissions();
  },
  methods: {
    viewDetails(task) {
      // 触发查看详情事件，将任务信息传递给父组件
      this.$emit('view-details', task);
    },
    showDeleteConfirmationDialog(row) {
      // 当确认按钮点击时触发，可以在这里调用实际的删除方法
      this.$emit('delete-task', row);
    }
  },
});
</script>
<style scoped>
.data-table {
  margin-left: 24px;
}

</style>

  