<template>
  <div class="footer-show">
    <!-- 操作按钮 -->
    <el-button @click="goBack" class="custom-reset-button"> {{ $t('ApplyAfterSales.previousStep') }}</el-button>
    <el-button @click="goMainPage" class="custom-reset-button"> {{ $t('ApplyAfterSales.return') }}</el-button>

    <!-- 提交按钮和确认框 -->
    <el-popconfirm
        :title="$t('ApplyAfterSales.confirmSubmit')"
        placement="top"
        width="300px"
        @confirm="handleSubmit"
        :confirm-button-text="$t('ApplyAfterSales.ok')"
        :cancel-button-text="$t('ApplyAfterSales.cancel')"
        confirm-button-type="success">
      <template #reference>
        <el-button
            :class="[isSubmitDisabled ? 'button-disabled-color' : 'button-background-color']"
            :disabled="isSubmitDisabled">{{ $t('ApplyAfterSales.submit') }}
        </el-button>
      </template>
    </el-popconfirm>

  </div>
</template>

<script>
import {updateAfterSalesData} from "@/api/api";
import dialogMixin from "@/mixins/dialogMixin";

export default {
  mixins: [dialogMixin],
  props: {
    tableData: Array,
    id: {
      type: String, // 或者根据您的实际情况设置适当的类型
      required: true
    }
  },
  computed: {
    isSubmitDisabled() {
      // 检查 tableData 是否为空或长度为0
      return !this.tableData || this.tableData.length === 0;
    }
  },
  // 组件逻辑
  methods: {
    async goBack() {

      if (this.$route.query.type === 'new') {
        // 获取传递的参数
        const formData = this.$route.query.formData
            ? JSON.parse(this.$route.query.formData)
            : {};
        // 执行新增的上一步逻辑，传递参数
        this.$router.push({
          path: '/FormPage',
          query: {formData: JSON.stringify(formData)}, // 使用query传递JSON字符串
        });
      } else {
        // 默认情况下的处理
        this.$router.go(-1); // 返回上一页
      }

    },

    goMainPage() {
      this.$router.push('/AfterSales');
    },
    async handleSubmit() {
      const id = this.id; // 替换为实际的 ID
      const now = new Date(); // 获取当前时间
      const formattedNow = now.toISOString(); // 将当前时间格式化为 ISO 字符串

      const data = {
        // 替换为实际需要发送的数据
        commit_status: 1,
        commit_at : this.formatDate(null, null, formattedNow),
      };

      try {
        await updateAfterSalesData(id, data);
        // 在这里进行页面跳转或显示成功消息等操作
        this.$router.push('/AfterSales'); // 提交成功后跳转到售后页面
      } catch (error) {
        console.error(this.$t('ApplyAfterSales.submitFailed'));
      }
    },
  }
};
</script>

<style scoped>
.button-disabled-color {
  background-color: rgb(245, 245, 245); /* 不可点击时的灰色背景 */
}

.footer-show {
  display: flex;
  gap: 5px;
  justify-content: center;
  margin-top: 20px;
}
</style>
