<template>

  <div class="search-bar">
    <el-form inline>
      <el-form-item>
        <label class="form-label">
          {{ $t('ApplyAfterSales.submissionProgress') }}
        </label>
        <el-select class="custom-select-width" v-model="submitProgress"
                   :label="$t('ApplyAfterSales.submissionProgress') "
                   :placeholder="$t('ApplyAfterSales.submissionProgressPlaceholder') " clearable>
          <el-option :label="$t('ApplyAfterSales.submitted')" value="1"></el-option>
          <el-option :label="$t('ApplyAfterSales.notSubmitted')" value="0"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <label class="form-label">{{ $t('ApplyAfterSales.processingProgress') }}</label>
        <el-select class="custom-select-width" v-model="processProgress"
                   :placeholder="$t('ApplyAfterSales.processingProgressPlaceholder') "
                   clearable>
          <el-option :label="$t('ApplyAfterSales.unprocessed')" value="0"></el-option>
          <el-option :label="$t('ApplyAfterSales.processing')" value="1"></el-option>
          <el-option :label="$t('ApplyAfterSales.completed')" value="2"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item class="form-item-button" style="margin-top: 36px;">
        <el-button class="button-background-color" @click="search"> {{ $t('ApplyAfterSales.search') }}</el-button>
        <el-button @click="reset" class="custom-reset-button">{{ $t('ApplyAfterSales.reset') }}</el-button>
      </el-form-item>

    </el-form>
  </div>
</template>


<script>
import {ref, getCurrentInstance} from 'vue';
import {ElSelect, ElOption, ElButton} from 'element-plus';

export default {
  components: {
    ElSelect,
    ElOption,
    ElButton,
  },
  setup() {
    // 使用 ref 初始化数据
    const submitProgress = ref('');
    const processProgress = ref('');
    // 获取 emit 函数
    const instance = getCurrentInstance();
    const emit = instance?.emit;
    // 定义搜索方法
    const search = () => {
      // 创建一个空对象来存储参数
      const payload = {};

      // 检查 submitProgress 的值是否存在，存在则添加到 payload 中
      if (submitProgress.value || submitProgress.value === '0') {
        payload["commit_status-eq"] = submitProgress.value;
      }

      // 检查 processProgress 的值是否存在，存在则添加到 payload 中
      if (processProgress.value || processProgress.value === '0') {
        payload["process_status-eq"] = processProgress.value;
      }

      // 如果 payload 不为空，就触发搜索事件，并传递参数给父组件
       emit('search', payload);
    };

    // 定义重置方法
    const reset = () => {
      // 触发重置事件，清空搜索条件
      submitProgress.value = '';
      processProgress.value = '';

      // 调用 search 方法以执行不带参数的搜索
      search();
    };

    return {
      submitProgress,
      processProgress,
      search,
      reset,
    };
  },
};
</script>
<style scoped>
.search-bar {
  display: flex;
  justify-content: flex-start;
  margin-top: 16px;
  margin-left: 24px;

  border: 1px solid rgb(236, 239, 239);
  background-color: rgb(255, 255, 255);
  border-radius: 2px;
  margin-right: 20px;
  padding: 16px 24px 5px 24px;
}

/* 对于小屏幕 */
@media (max-width: 600px) {
  .custom-select-width {
    width: 100%; /* 小屏幕上全宽 */
    margin-right: -15px;
    margin-left: 0;
    min-width: 300px; /* 或者你希望的最小宽度 */
  }
}

/* 对于中等屏幕 */
@media (min-width: 601px) and (max-width: 1024px) {
  .custom-select-width {
    width: 100%; /* 中等屏幕上半宽 */
    margin-right: -15px;
    margin-left: 0;
    min-width: 300px; /* 或者你希望的最小宽度 */
  }
}

/* 对于大屏幕 */
@media (min-width: 1025px) {
  .custom-select-width {
    width: 100%; /* 中等屏幕上半宽 */
    min-width: 300px; /* 或者你希望的最小宽度 */
    margin-right: -15px;
    margin-left: 0;
  }
}

</style>
  