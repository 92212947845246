<template>
  <div>
    <div class="custom-text">
      {{ $t('ApplyAfterSales.applyAfterSales') }}
    </div>
  </div>

  <div class="fault-page">
    <!-- 顶部标题和按钮区域 -->
    <HeaderShow :id="id" @refresh-table-data="refreshTableData" :loadData="loadData"/>

    <!-- 表格展示区域 -->
    <TableShow :tableData="tableData" :loadData="loadData" :loading="loading" :showOperationColumn="true"/>

    <PaginationBar :total-records="totalRecords" :current-page="currentPage" :page-size="pageSize"
                   @page-change="handlePageChange" @page-size-change="handlePageSizeChange"/>

    <!-- 底部按钮区域 -->
    <FooterShow :id="id" :tableData="tableData"/>
  </div>
</template>

<script>
import HeaderShow from './components/HeaderShow.vue';
import FooterShow from './components/FooterShow.vue';
import TableShow from './components/TableShow.vue';
import PaginationBar from './components/PageShow.vue';
import {getAfterSalesDetails} from "@/api/api";

export default {
  components: {
    HeaderShow,
    FooterShow,
    TableShow,
    PaginationBar
  },
  data() {
    return {
      id : '',
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示条数
      totalRecords: 0, // 总记录数
      loading: false, // 加载状态
    };
  },
  created() {
    // 在组件创建时加载所有数据
    this.id = this.$route.params.taskId;
    this.loadData();
  },
  methods: {
    // 处理分页变化事件
    handlePageChange(newPage) {
      this.currentPage = newPage;
      // 根据新的页码发送请求获取数据，并更新 tasks
      this.loadData(); // 重新加载数据
    },
    // 处理每页显示条数变化事件
    handlePageSizeChange(newPageSize) {
      this.pageSize = newPageSize;
      // 根据新的每页显示条数发送请求获取数据，并更新 tasks
      this.loadData(); // 重新加载数据
    },

    async loadData() {
      const page = this.currentPage;
      const pageSize = this.pageSize;
      const id = this.$route.params.taskId;
      this.loading = true; // 开始加载
      try {
        const query = { 'after_sale_id-eq': id };
        const data = await getAfterSalesDetails(page, pageSize, query);
        // 将从服务器获取的数据进行字段重命名
        this.tableData = data.data.map(item => ({
          fault_info_id: item.after_sale_id,
          motorAppearance: item.appearance_status,
          defectDescription: item.description,
          defectCategory: item.error_type,
          id: item.id,
          defectImages: item.image,
          image_url:  item.image_url,
          invoice: item.invoice,
          invoice_url: item.invoice_url,
          materialCode: item.material_code,
          SN: item.sn, // 根据值映射中文名称
          SNLabel: item.sn_label,
          sn_label_url : item.sn_label_url,
          defectVideos: item.video,
          video_url: item.video_url,
        }));
        this.totalRecords = data.meta.total;
        this.currentPage = data.meta.current_page;
        this.pageSize = typeof data.meta.per_page === 'string' ? parseInt(data.meta.per_page, 10) : data.meta.per_page;
      } catch (error) {
        console.error(this.$t('ProductManage.loadDataFailed'));
      } finally {
        this.loading = false; // 加载结束
      }
    },

    refreshTableData() {
      this.loadData();
    },
  },
};
</script>

<style scoped>

</style>
  