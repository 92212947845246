<template>
  <div class="error-page">
    <h1>403</h1>
    <p>Sorry, you don't have permission to access this page.</p>
    <router-link to="/">Go to Home</router-link>
  </div>
</template>

<script>
export default {
  name: 'ForbiddenPage',
};
</script>

<style scoped>
.error-page {
  text-align: center;
  padding: 100px 0;
}
.error-page h1 {
  font-size: 100px;
  margin-bottom: 10px;
}
.error-page p {
  font-size: 20px;
  margin-bottom: 20px;
}
</style>
