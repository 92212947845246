<template>
  <div>
    <div class="custom-text">
      {{ $t('ApplyAfterSales.applyAfterSales') }}
    </div>
  </div>
  <div>
    <el-steps :active="1" align-center>
      <el-step :title="$t('ApplyAfterSales.basicInformation')"/>
      <el-step :title="$t('ApplyAfterSales.malfunctionInformation')"/>
    </el-steps>
  </div>
  <div class="form-page" v-loading="loading" element-loading-text="Loading...">
    <div class="form">
      <div class="form-section">
        <el-form :model="formData" label-width="150px;">

          <el-row class="custom-row">
            <el-col :span="12" class="custom-col">
              <el-form-item :label="$t('ApplyAfterSales.customerName')" prop="customerName" label-width="150px"
                            :rules="{ required: true, message: $t('ApplyAfterSales.customerNamePlaceholder'), trigger: 'blur' }">
                <el-input class="custom-input-width" v-model="formData.customerName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1"></el-col> <!-- 减小间隔列的宽度 -->
            <el-col :span="11">
              <el-form-item prop="country" :label=" $t('ApplyAfterSales.country')" label-width="150px"
                            :rules="{ required: true, message: $t('ApplyAfterSales.countryEnterPlaceholder'), trigger: 'blur' }">
                <el-select class="custom-input-width" v-model="formData.country" filterable clearable
                           :placeholder="$t('ApplyAfterSales.countryPlaceholder')">
                  <el-option
                      v-for="item in countryOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12" class="custom-col">
              <el-form-item :label=" $t('ApplyAfterSales.address')" prop="address" label-width="150px"
                            :rules="{ required: true, message: $t('ApplyAfterSales.addressPlaceholder'), trigger: 'blur' }">
                <el-input class="custom-input-width" v-model="formData.address" required></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1"></el-col>
            <el-col :span="11">
              <el-form-item :label="$t('ApplyAfterSales.brand')" prop="brand" label-width="150px"
                            :rules="{ required: true, message: $t('ApplyAfterSales.brandPlaceholder'), trigger: 'blur' }">
                <el-input class="custom-input-width" v-model="formData.brand" required></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12" class="custom-col">
              <el-form-item :label="$t('ApplyAfterSales.phone')" prop="phone" label-width="150px"
                            :rules="{ required: true, message:$t('ApplyAfterSales.phonePlaceholder'), trigger: 'blur' }">
                <el-input class="custom-input-width" v-model="formData.phone" required></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1"></el-col>
            <el-col :span="11">
              <el-form-item :label="$t('ApplyAfterSales.pICKUPADDRESS')" prop="pickupAddress" label-width="150px"
                            :rules="{ required: true, message: $t('ApplyAfterSales.pickupAddressPlaceholder'), trigger: 'blur' }">
                <el-input class="custom-input-width" v-model="formData.pickupAddress" required></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12" class="custom-col">
              <el-form-item label=" E-MAIL:" prop="email" label-width="150px"
                            :rules="{ required: true, message: $t('ApplyAfterSales.emailPlaceholder'), trigger: 'blur' }">
                <el-input class="custom-input-width" v-model="formData.email" required></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1"></el-col>

            <el-col :span="11">
              <el-form-item :label="$t('ApplyAfterSales.cONTACTPERSON')" prop="contact" label-width="150px"
                            :rules="{ required: true, message: $t('ApplyAfterSales.contactPersonPlaceholder'), trigger: 'blur' }">
                <el-input class="custom-input-width" v-model="formData.contact" required></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div class="form-actions">
        <el-button @click="goBack" class="custom-reset-button">{{ $t('ApplyAfterSales.return') }}</el-button>
        <el-button class="button-background-color" @click="goToNextStep">{{ $t('ApplyAfterSales.nextStep') }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { showAfterSalesData, updateAfterSalesData} from "@/api/api";
import dialogMixin from "@/mixins/dialogMixin";
import countryMixin from "@/mixins/countryMixin";

export default {
  mixins: [dialogMixin,countryMixin],
  data() {
    return {
      activeStep: 1, // 当前步骤
      formData: {
        customerName: '',
        address: '',
        phone: '',
        email: '',

        country: null,
        brand: '',
        pickupAddress: '',
        contact: '',
      },
      countryOptions: [],
      loading: false, // 加载状态
    };
  },
  async created() {
    // 获取路由参数中的 id
    const id = this.$route.params.taskId;
    this.loading = true; // 开始加载
    try {
      // 调用后端接口获取数据
      const afterSalesInformation = await showAfterSalesData(id);
      // 将后端返回的数据填充到表单中
      this.formData.customerName = afterSalesInformation.data.customer_name;
      this.formData.address = afterSalesInformation.data.address;
      this.formData.phone = afterSalesInformation.data.phone;
      this.formData.email = afterSalesInformation.data.email;
      this.formData.country = afterSalesInformation.data.country_id;
      this.formData.brand = afterSalesInformation.data.brand;
      this.formData.pickupAddress = afterSalesInformation.data.pick_up_address;
      this.formData.contact = afterSalesInformation.data.contact;
    } catch (error) {
      this.$message.error(this.$t('ApplyAfterSales.dataFetchError'));
    } finally {
      this.loading = false; // 加载结束
    }
  },

  mounted() {
    this.fetchCountryOptions();
  },

  methods: {
    goBack() {
      // 使用 Vue Router 返回上一个页面
      this.$router.go(-1); // 或者使用 this.$router.back();
    },

    async goToNextStep() {
      // 遍历必填项字段名
      const requiredFields = [
        'customerName',
        'country',
        'address',
        'brand',
        'phone',
        'pickupAddress',
        'email',
        'contact',
      ];

      // 验证是否填写必填项
      let hasEmptyField = false;
      for (const field of requiredFields) {
        if (!this.formData[field]) {
          hasEmptyField = true;
          // 显示提示信息，你可以使用 Element Plus 的 Message 组件或其他方式显示提示信息
          this.$message.error(this.$t('ApplyAfterSales.fillAllRequired'));
          break; // 一旦有一个未填写的必填项，就跳出循环
        }
      }

      // 如果没有未填写的必填项，前进到下一步
      if (!hasEmptyField) {
        // 表单校验成功，尝试提交数据
        let res = await this.updateDataToBackend();
        if (res?.code !== 0) {
          console.error(this.$t('ApplyAfterSales.addDataFailed') + (res?.message || ''));
          return;
        }
        if (this.activeStep < 2) {
          this.activeStep++;
          this.$router.push({path: `/FaultPage/${this.$route.params.taskId}`, query: {type: 'edit'}});
        }
      }

    },

    // 新增保存数据的方法
    async updateDataToBackend() {
      try {
        let searchCondition = {
          customer_name: this.formData.customerName,
          country_id: this.formData.country,
          address: this.formData.address,
          brand: this.formData.brand,
          phone: this.formData.phone,
          pick_up_address: this.formData.pickupAddress,
          email: this.formData.email,
          contact: this.formData.contact,
        };
        return await updateAfterSalesData(this.$route.params.taskId, searchCondition)
      } catch (error) {
        // 添加错误提示
        console.error(this.$t('ApplyAfterSales.retryLater'));
      }
    },

  },
};
</script>

<style scoped>
.custom-row {
  display: flex;
  align-items: center; /* 垂直居中对齐 */
}

.custom-col {
  padding-right: 10px;
  padding-left: 10px;
}

/* 默认样式，适用于大屏幕 */
.custom-input-width {
  width: 100%; /* 大屏幕上宽度为 430px */
}

/* 页面样式 */
.form-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

/* 表单样式 */
.form {
  width: 80%;
}

/* 表单部分样式 */
.form-section {
  margin-top: 20px;
  padding: 20px;
}

/* 操作按钮样式 */
.form-actions {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

</style>
    